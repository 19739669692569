import React from 'react'

import styled, { keyframes }  from "styled-components"

const ActiveBadge = ({Data}) => {

    const fadeIn = keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
    `

  const LoadingContainer = styled.div`
        display:inline-block;
        width:10px;
        height:10px;
        border-radius:20px;
        animation: ${fadeIn} 1s ease-in-out infinite;
        animation-direction: alternate;
        margin-right:10px;
        background-color:#FF4242;
    `

    return(
        <MainContainer>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <LoadingContainer/>
                <a>En cours</a>
            </div>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display:inline-block;
    background-color:#00FFAD;
    padding:10px 20px 10px 20px;
    border-radius:20px;
    font-size:10px;
    font-weight:600;
`

export default ActiveBadge