import React from 'react'

import Logo from "../../static/logo_pendu_black.svg"
import Screen from "../../static/appStore-8.png"
import BadgeIOS from "../../static/badge_appStore_fr.svg"
import BadgeIOS_EN from "../../static/badge_appStore_en.svg"

import ActiveBadge from './ActiveBadge'

import Griffon from "../../static/griffon.png"

import styled from "styled-components"

const LogoAndScreen = ({locale}) => {
    return(
        <MainContainer>
            <LeftContainer>
                <img src={Logo} style={{width:'100%',maxWidth:'250px',opacity:0.9,top:'0px'}} alt='bijour'/>
                <h1 style={{textAlign:'center'}}>Pendu<br/>du<br/>Design</h1>
                {locale === 'fr' && <p style={{textAlign:'center',marginTop:'20px'}}>Le Pendu du Design<br/>est un jeu disponible<br/>comme application mobile<br/>sur iOS</p>}
                {locale !== 'fr' && <p style={{textAlign:'center',marginTop:'20px'}}>The Hangman of Design<br/>is available as mobile gaming app<br/>on iOS</p>}
                <StoreMobileLink>
                    {locale === 'fr' && <div>
                        <div style={{marginTop:'35px'}}><a href="https://apps.apple.com/fr/app/pendu-du-design/id1547842697" target="_blank" rel="noreferrer"><img src={BadgeIOS} alt='bijour' style={{maxWidth:'260px',width:'100%',height:'auto',opacity:0.9}}/></a></div>
                    </div>}
                    {locale !== 'fr' && <div>
                        <div style={{marginTop:'35px'}}><a href="https://apps.apple.com/fr/app/pendu-du-design/id1547842697" target="_blank" rel="noreferrer"><img src={BadgeIOS_EN} alt='bijour' style={{maxWidth:'260px',width:'100%',height:'auto',opacity:0.9}}/></a></div>
                    </div>}
                </StoreMobileLink>
            </LeftContainer>
            <RightContainer>
                {/* <ActiveBadge/> */}
                <a href="/#"><img src={Screen} alt='bijour' style={{width:'100%',height:'auto',maxWidth:'248px',opacity:0.9}}/></a>
                {locale === 'fr' && <div>
                    <div style={{marginBottom:'5px'}}><a href="https://apps.apple.com/fr/app/pendu-du-design/id1547842697" target="_blank" rel="noreferrer"><img src={BadgeIOS} alt='bijour' style={{maxWidth:'260px',width:'100%',height:'auto',opacity:0.9}}/></a></div>
                </div>}
                {locale !== 'fr' && <div>
                    <div style={{marginBottom:'5px'}}><a href="https://apps.apple.com/fr/app/pendu-du-design/id1547842697" target="_blank" rel="noreferrer"><img src={BadgeIOS_EN} alt='bijour' style={{maxWidth:'260px',width:'100%',height:'auto',opacity:0.9}}/></a></div>
                </div>}
            </RightContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    margin:auto;
    display:flex;
    flex-direction:row;
    min-height:933px;
    border-top:solid #DDDDDD 1px;
    padding:0px;
    background-color:#F2F2F2;
    @media (max-width: 724px) {
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
`

const LeftContainer = styled.div`
    display:flex;
    flex-direction:column;
    width:50%;
    align-items:center;
    @media (max-width: 724px) {
        width:100%;
        min-height:633px;
        border-bottom:solid #DDDDDD 1px;
        padding:0px 0px 80px 0px;
    }
`

const RightContainer = styled.div`
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    @media (max-width: 724px) {
        width:100%;
        min-height:633px;
        padding:80px 0px 80px 0px;
    }
`

const StoreMobileLink = styled.div`
    display:none;
    @media (max-width: 724px) {
        display:block;
    }
`

export default LogoAndScreen