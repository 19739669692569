import * as React from "react"

import { useIntl } from "gatsby-plugin-react-intl"
import styled from "styled-components"

import LogoAndScreen from '../components/logoAndScreen'
import LogoAndDescription from '../components/logoAndDescription'
import Manifeste from '../components/manifeste'
import Publication from '../components/publication'
import Footer from "../components/footer"
import { Seo } from "../components/seo"

import Header from '../components/header'

import ShopTile from '../components/ShopTile'
import VersionBanner from "../components/versionBanner"

import '../styles/global.css'

import diaCover from "../../static/tuile/06.png"
import empireCover from "../../static/tuile/05.png"
import style1Cover from "../../static/tuile/01.png"
import style2Cover from "../../static/tuile/02.png"
import designer1Cover from "../../static/tuile/03.png"
import designer2Cover from "../../static/tuile/04.png"

const data_en = [
  [{title:"Ariane's\nDiagrams",description:"Learn to recognize weave patterns. Deep dive into a maze",cover:diaCover,color:'#272424',backgroundColor:'#e5e5e5',live:false},
  {title:"The sins\nof Empire",description:"Look for\nmythological signs\nwithin the Empire style collection",cover:empireCover,color:'white'}],
  [{title:"Styles vol.1",description:"Learn to recognize\nFrench furniture's styles",cover:style1Cover,color:'#272424'},
    {title:"Styles vol.2",description:"Learn to recognize\nFrench furniture's styles",cover:style2Cover,color:'#272424'}],
  [{title:"50 Objects\nfrom 2000 to 2020 Vol.1",description:"Try to recognize Designer's objects\nwith this Pendu of 50 Objects",cover:designer1Cover,color:'#272424'},
  {title:"50 Objects\nfrom 2000 to 2020 Vol.2",description:"Try to recognize Designer's objects\nwith this Pendu of 50 Objects",cover:designer2Cover,color:'#272424'}]
]

const data = [
  [{title:"Diagramme\nd'Ariane",description:"Apprenez à reconnaître les tissus. Plongez dans un labyrinthe sans début ni fin",cover:diaCover,color:'#272424',backgroundColor:'#e5e5e5',live:false},
  {title:"Les signes\nde l'Empire",description:"Partez à la recherche des signes mythologiques\ndu style Empire",cover:empireCover,color:'white'}],
  [{title:"Les Styles Vol.1",description:"Apprenez à reconnaître\nles styles de mobilier Français",cover:style1Cover,color:'#272424'},
    {title:"Les Styles Vol.2",description:"Apprenez à reconnaître\nles styles de mobilier Français",cover:style2Cover,color:'#272424'}],
  [{title:"50 Objets\nde 2000 à 2020 vol.1",description:"Testez vos connaissances\nà travers ce pendu de 50 Objets\ndessinés par des designers\nde 2000 à 2020 ",cover:designer1Cover,color:'#272424'},
  {title:"50 Objets\nde 2000 à 2020 vol.2",description:"Testez vos connaissances\nà travers ce pendu de 50 Objets\ndessinés par des designers\nde 2000 à 2020 ",cover:designer2Cover,color:'#272424'}]
]

const IndexPage = ({ location }) => {
  const intl = useIntl()
  const locale = intl.locale

  return (
    <main>
        <div style={{ zIndex:'2', position:'relative', display:'inline-block', marginBottom:'400px', width:"100%" }}>
            {/* <VersionBanner/> */}
            <Header/>
            <ShopTile/>
            <LogoAndScreen locale={locale}/>
            <LogoAndDescription locale={locale}/>
            { locale === 'fr' && <div>{ data.map((item,key) => <Publication Data={item} key={key}/>  ) }</div>}
            { locale !== 'fr' && <div>{ data_en.map((item,key) => <Publication Data={item} key={key}/>  ) }</div>}
            {/* <Manifeste locale={locale}/> */}
            <Spacer/>
        </div>
        <div style={{position:'fixed',bottom:'0',width:"100%"}}>
          <Footer locale={locale}/>
        </div>
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <Seo />
)

const Spacer = styled.div`
    height:120px;
    width:100%;
    background-color:#F2F2F2;
    border-top: solid #DDDDDD 1px;
    border-bottom: solid #DDDDDD 1px;
`