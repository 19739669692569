import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const ShopTile = ({ locale }) => {

    return(
        <MainContainer>
            <StyledLink fade to="/shop" >
            <LeftContainer>
                <StaticImage
                    src="../images/Signe_empire_poster_miniature.png"
                    alt="Poster Signe de l'empire"
                    placeholder="dominantColor"
                    layout="fixed"
                    width={200}
                    height={286}
                />
                <h2 style={{paddingTop:'15px',margin:'0px',fontSize:'14px', lineHeight:'12px'}}>34.99 € - Affiche a3</h2>
            </LeftContainer>
            </StyledLink>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display:flex;
    flex-direction:row;
    min-height:233px;
    border-top:solid #DDDDDD 1px;
    background-color:#CDC299;
    justify-content:center;
    @media (max-width: 724px) {
        min-height:533px;
        flex-direction:column;
        justify-content: center;
    }
`

const StyledLink = styled(props => <AniLink {...props} />)`
    display:flex;
    cursor:grab;
    padding:40px 60px 40px 60px;
    border-left:solid #BCB189 1px;
    border-right:solid #BCB189 1px;
    @media (max-width: 724px) {
        min-height:533px;
        flex-direction:column;
        justify-content: center;
    }
    &:hover {
        background-color: #BCB189;
      }
`

const LeftContainer = styled.div`
    position:relative;
    display:flex;
    text-align:center;
    justify-content:end;
    align-items:center;
    flex-direction:column;
    padding:10px;
    @media (max-width: 724px) {
        width:100%;
        justify-content:center;
    }
`

const RightContainer = styled.div`
    display:flex;
    flex-direction:row;
    color:black;
    padding: 40px 40px 0px 34px;
    @media (max-width: 724px) {
        width:100%;
        justify-content:center;
        padding:40px 0px 0px 0px;
    }
`

const LeftAbsoluteImageGalery = styled.div`
    position:relative;
    width:100%;
    height:100%;
    @media (max-width: 724px) {
    }
`

const RightContainerDetail = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:end;
    @media (max-width: 724px) {
        justify-content:center;
        text-align:center;
    }
`

const Legende = styled.a`
    font-size:11px;
    font-style: regular;
    font-weight: 400;
    line-height:14px;
`

const Spacer = styled.div`
    height:60px
`

export default ShopTile