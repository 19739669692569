import React from 'react'

import styled from "styled-components"

const LogoAndDescription = ({locale}) => {
    return(
        <MainContainer>
            <LeftContainer>
                <h1>Pendu<br/>du<br/>Design</h1>
            </LeftContainer>
            <RightContainer>
                <div style={{textAlign:'center'}}>
                    {locale === 'fr' && <p>Ce jeu accessible à tous<br/>permet de découvrir<br/>ou redécouvrir l’histoire du Design,<br/>les tendances, les styles<br/>et leurs créateurs emblématiques.<br/><br/>Vous allez redécouvrir les formes<br/>et les objets qui ont inspiré notre quotidien.</p>}
                    {locale !== 'fr' && <p>Hangman of Design <br/>is an educational game<br/>let's walk through the history of Design,<br/>furniture style and their emblematic creators<br/><br/>You will discover shapes and forms<br/>from objects who inspired<br/>our day to day life<br/></p>}
                </div>
            </RightContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    margin:auto;
    display:flex;
    flex-direction:row;
    min-height:933px;
    border-top:solid #DDDDDD 1px;
    padding:0px;
    background-color:#F2F2F2;
    @media (max-width: 724px) {
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
`

const LeftContainer = styled.div`
    width:50%;
    display:flex;
    text-align:center;
    justify-content:center;
    align-items:center;
    border-right:solid #DDDDDD 1px;
    @media (max-width: 724px) {
        width:100%;
        min-height:633px;
        border-right:solid #DDDDDD 0px;
        border-bottom:solid #DDDDDD 1px;
    }
`

const RightContainer = styled.div`
    width:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    @media (max-width: 724px) {
        width:100%;
        min-height:633px;
    }
`


export default LogoAndDescription