import React from 'react'
import styled from "styled-components"

import Image from '../../static/lavis5_square.jpg'

const Manifeste = ({locale}) => {
    return(
        <MainContainer>
        {locale === 'fr' && <LeftContainer>
            <h1>Manifeste<br/><br/></h1>
            <p>
              Apprenez à...<br/><br/>
              - Reconnaître les objets de designer à travers le jeu
              du pendu<br/>
              - Visualiser votre environnement industriel.<br/>
              - Redécouvrir ces formes de tous les jours.<br/><br/>
              
              Devenez cet utilisateur averti qui sera reconnaitre<br/>
              les formes, les styles, les tendances qui composent<br/>
              notre société.<br/><br/>
              
              Observez et Redécouvrez ce monde !<br/>
              Ce monde aux formes étranges !<br/><br/>
              
              Familiarisez vous à ce monde domestique, Ce monde aux<br/>
              formes fantastiques !<br/>
              Dépassez l'usage ! Dépassez l'objet !<br/><br/>
              
              Pour commencer à savoir lire le langage visuel des<br/>
              objets, Voyez ces signes.<br/><br/>
              
              À travers le jeu du pendu, l'intention est de vous<br/>
              donner une culture générale du Design.<br/><br/>
              
              L’application se nourrit de tous les champs du<br/>
              Design : du Design graphique, de l’architecture au<br/>
              Design produit.<br/>
            </p>
            </LeftContainer>}
            {locale !== 'fr' && <LeftContainer>
                <h1>Manifesto<br/><br/></h1>
                <p>
                Learn to recognise designer's objects with this hangman.<br/>
                <br/>
                Learn to see your industrial environment.<br/>
                Learn to recognise shapes of everyday.<br/>
                <br/>
                Learn to become a conscious user. A user able to play with what our society is producing : signes, shapes, esthetic. <br/>
                <br/>
                Stop to be a consumer, things have values !<br/>
                <br/>
                Learn to see the world around you !<br/>
                <br/>
                This world with fantastic shapes !<br/>
                Learn to read this wonderful world, this fantastic world !<br/>
                <br/>
                Start to be able to read this visual language. The visual language produced by our day to day Objects. <br/>
                <br/>
                Because behind you... designers are there... to tell you wonderful stories...<br/>
                <br/>
                </p>
            </LeftContainer>}
            <SecondContainer>
                <svg>
                  <filter id="turbulence" x="0" y="0" width="100%" height="100%" colorInterpolationFilters="sRGB">
                    <feTurbulence id="sea-filter" numOctaves="1" seed="1" baseFrequency="0.01 0.01"></feTurbulence>
                    <feDisplacementMap scale="8" in="SourceGraphic"></feDisplacementMap>
                    <animate xlinkHref="#sea-filter" attributeName="baseFrequency" dur="60s" keyTimes="0;1;1" values="0.02 0.06;0.04 0.08;0.02 0.06" repeatCount="indefinite"/>
                  </filter>
                </svg>
            </SecondContainer>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height:933px;
    border-top: solid #DDDDDD 1px;
    padding: 0px;
    background-color: #F2F2F2;
    @media (max-width: 724px) {
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
`

const SecondContainer = styled.div`
    width:50%;
    display:flex;
    background-image:url(${Image});
    background-size:cover;
    background-position:bottom;
    background-repeat:no-repeat;
    background-color:#F2F2F2;
    filter: url("#turbulence");
    transform: translateZ(0);
    @media (max-width: 724px) {
        display:none;
    }
`

const LeftContainer = styled.div`
    width:50%;
    display:flex;
    padding-top:80px;
    padding-bottom:80px;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    align-items:center;
    border-right:solid #DDDDDD 1px;
    @media (max-width: 724px) {
        border-right:solid #DDDDDD 0px;
        width:80%;
    }
`

export default Manifeste