import React from 'react'

import styled from "styled-components"

import ActiveBadge from './ActiveBadge'

const Publication = ({Data}) => {

    let item2 = Data[1].cover

    let itemString = Data[0].title.split("\n")
    let itemString1 = Data[1].title.split("\n")

    const MainContainer = styled.div`
        margin:auto;
        display:flex;
        flex-direction:row;
        min-height:933px;
        border-top:solid #DDDDDD 1px;
        padding:0px;
        background-color:#F2F2F2;
        @media (max-width: 724px) {
            flex-direction:column;
            justify-content:center;
            align-items:center;
        }
    `

    const TileContainer = styled.div`
        width:271px;
        height:291px;
        background-size:cover;
        background-position:bottom;
        background-repeat:no-repeat;
        background-image:url(${Data[0].cover});
        text-align:left;
        padding:10px;
    `

    const TileContainer2 = styled.div`
        width:271px;
        height:291px;
        background-size:cover;
        background-position:bottom;
        background-repeat:no-repeat;
        background-image:url(${Data[1].cover});
        text-align:left;
        padding:10px;
    `

    const LeftContainer = styled.div`
        width:50%;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        border-right:solid #DDDDDD 1px;
        background-color:${Data[0].backgroundColor ? Data[0].backgroundColor: 'none'};
        @media (max-width: 724px) {
            width:100%;
            min-height:633px;
            border-right:solid #DDDDDD 0px;
            border-bottom:solid #DDDDDD 1px;
        }
    `

    const RightContainer = styled.div`
        width:50%;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        background-color:${Data[1].backgroundColor ? Data[1].backgroundColor: 'none'};
        @media (max-width: 724px) {
            width:100%;
            min-height:633px;
        }
    `

    return(
        <MainContainer>
            <LeftContainer>
                {Data[0].live ? <div style={{paddingBottom:'20px'}}><ActiveBadge/></div> : null}
                <TileContainer>
                    {itemString.map((el,key) => <h1 style={{color:Data[0].color}} key={key}>{el}</h1>  )}
                    <p style={{fontWeight: '600',fontSize: '14px',lineHeight: '22px',paddingTop: '12px',color:'#02336D',color:Data[0].color}}>{Data[0].description}</p>
                </TileContainer>
            </LeftContainer>
            {item2 !== "" && <RightContainer>
                {Data[1].live ? <div style={{paddingBottom:'20px'}}><ActiveBadge/></div> : null}
                <TileContainer2>
                    {itemString1.map((el,key) => <h1 style={{color:Data[1].color}} key={key}>{el}</h1>  )}
                    <p style={{fontWeight: '600',fontSize: '14px',lineHeight: '22px',paddingTop: '12px',color:'#02336D',color:Data[1].color}}>{Data[1].description}</p>
                </TileContainer2>
            </RightContainer>}
        </MainContainer>
    )
}

export default Publication