import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const versionBanner = ({ locale }) => {

    return(
        <MainContainer>
            <StyledLink fade to="/noteversion" >
                <a>Note de version</a>
            </StyledLink>
        </MainContainer>
    )
}

const MainContainer = styled.div`
    display:flex;
    flex-direction:row;
    border-top:solid #D4DCBC 1px;
    background-color:#F6FFDE;
    justify-content:center;
    @media (max-width: 724px) {
        flex-direction:column;
        justify-content: center;
        text-align:'center';
    }
`

const StyledLink = styled(props => <AniLink {...props} />)`
    display:flex;
    cursor:grab;
    padding:15px;
    border-left:solid #D4DCBC 1px;
    border-right:solid #D4DCBC 1px;
    @media (max-width: 724px) {
        flex-direction:column;
        justify-content: center;
        text-align:'center';
    }
    &:hover {
        background-color: #D4DCBC;
      }
`

export default versionBanner