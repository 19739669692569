import React from "react"

import Logo from '../../static/icon.png'

export const Seo = () => {

  const siteURL = "https://www.pendududesign.com"

  const seo = {
    title: "Pendu du Design",
    description: `Le Pendu du Design est un jeu disponible comme application mobile sur iOS`,
    url: siteURL,
    image: ""+siteURL+Logo,
    twitterUsername: `@charles_Frment`,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="author" content="Charles Froment"/>
      <meta name="robots" content="index, follow"/>
      <meta name="og:title" property="og:title" content={seo.title}></meta>
      <meta property="og:description" content={seo.description}/>
      <meta property="og:image" content={seo.image}/>
      <meta property="og:url" content={seo.url}/>
      <meta property="og:site_name" content={seo.title}/>
      <meta name="twitter:card" content="app" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="theme-color" content='#17181b'/>
      <meta name="apple-itunes-app" content="app-id=1547842697"/>
      <meta name="theme-color" content="#2F4061"></meta>
      <link rel="icon" type="image/png" href={seo.image}></link>
    </>
  )
}


